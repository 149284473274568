import React from 'react';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import LandingSearchForm from './LandingSearchForm/LandingSearchForm';
import AnimatedText from 'react-animated-text-content';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import css from './SectionHero.module.css';

function SectionHero(props) {
  const { history, isLandingPage } = props;

  const intl = useIntl();
  const routeConfiguration = useRouteConfiguration();
  const handleSubmit = values => {
    const { location = {}, dates, ...rest } = values;
    const { search, selectedPlace = {} } = location;
    const { bounds } = selectedPlace;
    const searchQuery = {
      address: search,
      dates,
      bounds,
      search,
      ...rest,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchQuery));
  };

  return (
    <div className={css.heroContainer}>
      <div className={css.contentWidth}>
        <div className={css.heroContent}>
          {/* <p className={css.sectionSubHeading}>
            <FormattedMessage id="LandingPage.heading" />
          </p> */}
          <div className={css.animatedText}>
              The Less Expensive Way
          </div>

          <p>Vacation rentals without the extra fees</p>
          <div className={css.heroSearchSec}>
            <LandingSearchForm
              rootClassName={css.landingSearchForm}
              onSubmit={handleSubmit}
              intl={intl}
              isLandingPage={isLandingPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHero;
