import React from 'react';
import { Icons } from '../../components';
import css from './LandingPage.module.css';

function SectionPropertyInfo() {
  return (
    <div className={css.propertySection}>
      <div className={css.contentWidth}>
        <div className={css.sectionContent}>
          <div className={css.sectionHead}>
            <h2>The BnBunite Way</h2>
            <h1>Private home rentals for your friends & family</h1>
            <p>
              A company that goes beyond the best price. With our 24/7 customer support, our
              rewards, and scholarship program, why would you book any other way?
              #ChooseFreedomNotFees
            </p>
          </div>
          <div className={css.hospitalitySec}>
            <div className={css.infoBlock}>
              <div className={css.infoIcon}>
                <Icons name="sleep" />
              </div>
              <h3>Host Enjoy</h3>
              <ul>
                <li>
                  <span>Property damage & liability with every reservation.</span>
                </li>
                <li>
                  {' '}
                  <span>No commission, subscription, and hosting free.</span>
                </li>
                <li>
                  <span> Taxes paid on your behalf.</span>
                </li>
                <li>
                  <span>
                    More money in your pocket because you’re now able to escape the extra fees from
                    the other platforms.
                  </span>
                </li>
              </ul>
            </div>
            <div className={css.infoBlock}>
              <div className={css.infoIcon}>
                <Icons name="dollar" />
              </div>
              <h3>Guest Enjoy</h3>
              <ul>
                <li>
                  <span>
                    A 4% service fee, not the ridiculous 14+ percent from those other platforms.
                  </span>
                </li>
                <li>
                  <span>
                    Payment Protection when booked on BnBunite.com
                  </span>
                </li>
              </ul>
            </div>
            <div className={css.infoBlock}>
              <div className={css.infoIcon}>
                <Icons name="heartHand" />
              </div>
              <h3>Give Back</h3>
              <ul>
                <li>
                  <span>15% of our profits goes back to our customers & charity.</span>
                </li>
                <li>
                  {' '}
                  <span>
                    College scholarship for our customers{' '}
                    <a href="/scholarship" target="_self">
                      See Details
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    Rewards Program: We don’t charge you more and then give you your own money back
                    in platform points
                    <a href="/monthly-cash-program" target="_self">
                      See Details
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionPropertyInfo;
