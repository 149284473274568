import React from 'react';
import Carousel from 'react-multi-carousel';
import '../../styles/react-multi-carousel/React-Multi-Carousel.css';
import { ListingCard } from '../../components';
import { cardRenderSizes } from '../SearchPage/SearchResultsPanel/SearchResultsPanel';
import css from './LandingPage.module.css';

function SectionFeaturedHomes(props) {
  const { listings } = props;
  return (
    <div className={css.findComfortableplace}>
      <div className={css.findPlaceHead}>
        <h2 className={css.sectionTitle}>Recently Added Properties</h2>
        {/* <p className={css.sectionSubHeading}>Amet minim mollit non deserunt ullamco</p> */}
      </div>
      <div className={css.featuredCardSec}>
        {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(true)}
            setActiveListing={() => {}}
          />
        ))}
      </div>
      {/* <Carousel
        additionalTransfrom={0}
        arrows={true}
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container-padding-bottom"
        dotListClass=""
        draggable
        focusOnSelect
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 40,
          },
          Smalldesktop: {
            breakpoint: {
              max: 1440,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 580,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
          mobile: {
            breakpoint: {
              max: 580,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(true)}
            setActiveListing={() => {}}
          />
        ))}
      </Carousel> */}
    </div>
  );
}

export default SectionFeaturedHomes;
