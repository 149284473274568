import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { withViewport } from '../../util/uiHelpers';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { useConfiguration } from '../../context/configurationContext';
import { isArrayLength } from '../../util/genericHelpers';

import css from './LandingPage.module.css';

//Static assets
import facebookImage from '../../assets/biketribe-facebook-sharing-1200x630.jpg';
import twitterImage from '../../assets/biketribe-twitter-sharing-600x314.jpg';

//Section pages
import SectionHero from './SectionHero';
import SectionMoreLinks from './SectionMoreLinks';
import SectionFeaturedHomes from './SectionFeaturedHomes';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import SectionPropertyInfo from './SectionPropertyInfo';

export const LandingPageComponent = props => {
  const { intl, scrollingDisabled, history, listings } = props;

  const config = useConfiguration();
  const siteTitle = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.marketplaceRootURL}${facebookImage}`;

  const [scroll, setScroll] = useState(false);
  const isHeaderSticky = scroll ? css.sticky : '';
  useEffect(() => {
    let isMounted = true;
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        if (isMounted) {
          setScroll(window.scrollY > 0);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        isMounted = false; // This is the cleanup for event listener
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.marketplaceRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={<TopbarContainer isWhiteBgNav={true} isHeaderSticky={isHeaderSticky} />}
        footer={<FooterContainer />}
      >
        <div className={css.pageContent}>
          <SectionHero isLandingPage history={history} />
          <SectionPropertyInfo />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { currentPageResultIds } = state.LandingPage;
  const listings = getListingsById(state, currentPageResultIds);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(LandingPageComponent);

export default LandingPage;
